import * as React from "react";
import { List, Datagrid, TextField, EmailField, DateField, Edit, SimpleForm, TextInput, Create } from 'react-admin';

const UserTitle = props => {
    return <span>{props.record ? `${props.record.name}` : ''}</span>;
};

export const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <EmailField source="email" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export const UserEdit = props => {
    const transform = (data) => {
        delete data.registration_confirmation_token;
        delete data.name;
        return data;
    };

    return (<Edit {...props} title={<UserTitle />} transform={transform}>
        <SimpleForm>
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="phone_number" />
            <TextInput source="email" />
        </SimpleForm>
    </Edit>)
};

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="phone_number" />
            <TextInput source="email" />
        </SimpleForm>
    </Create>
);
