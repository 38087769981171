import * as React from "react";
import { Datagrid, TextField, EmailField, Button, Loading, ListContextProvider, useRedirect, useNotify, useDataProvider } from 'react-admin';
import { useQuery } from "react-admin";

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import keyBy from 'lodash/keyBy';

export const AddNewAdminGrid = props => {
    const redirect = useRedirect();
    const notify = useNotify();

    const dataProvider = useDataProvider();
    const select = (userId) => dataProvider.create(
        `productions/${props.match.params.productionId}/admins`, {
            data: {
                user_id: userId,
                production_id: props.match.params.productionId
            }
        }
    ).then(() => {
        notify('New admin added', 'success')
        return redirect(`/productions/${props.match.params.productionId}/show/admins`)
    })

    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'users',
        payload: {
            pagination: {},
            sort: { field: 'id', order: 'ASC' },
            filter: { 'not_production_admin': props.match.params.productionId },
        }
    });

    if (loading) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    return (
        <Container component={Paper} disableGutters={true}>
            <ListContextProvider
                value={{
                    basePath: '/users',
                    data: keyBy(data, 'id'),
                    ids: data.map(({ id }) => id),
                    currentSort: { field: 'id', order: 'ASC' },
                    selectedIds: [],
                    resource: 'users'
                }}
            >
                <Datagrid rowClick={select}>
                    <TextField source="id" />
                    <TextField source="name" />
                    <EmailField source="email" />
                    <Button label="Select" />
                </Datagrid>
            </ListContextProvider>
        </Container>
    );
};