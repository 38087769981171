import React, { useCallback, useMemo, useState } from 'react';
import { SimpleForm, PasswordInput, SaveContextProvider, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Paper, Container } from '@material-ui/core';

// The form we display to the user, which takes proposal parameters. A FormSpy
// is used to convert those into something we can post to the creation endpoint
export const ValidateUserForm = props => {
    const user = decodeURIComponent(props.match.params.user);
    const token = decodeURIComponent(props.match.params.token);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const [saving, setSaving] = useState();

    const handleFail = useCallback((a) => {
        notify(a, 'error');
        console.log(a);
    }, [notify])

    const handleSave = useCallback((values) => {
        setSaving(true);
        const data = {
            'password': values.password,
            'confirm': values.confirm,
            'email': user,
            'token': token
        };
        dataProvider.create(
            'validate',
            { data: data }
        ).then(() => {
            setSaving(false);
            notify("The password was set successfully", 'success');
            redirect('/login')
        }).catch((error) => {
            notify(error.message, 'error');
        })

      }, [dataProvider, notify, redirect, user, token]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        setOnFailure: handleFail,
        saving
    }), [saving, handleSave, handleFail]);

    return (
        <Container component={Paper}>
            <h2>Verify account</h2>
            <p>To access the admin panel you need to set a password below.</p>
            <SaveContextProvider value={saveContext}>
                <SimpleForm save={handleSave}>
                    <PasswordInput name="password" label="Password" />
                    <PasswordInput name="confirm" label="Confirm password" />
                </SimpleForm>
            </SaveContextProvider>
        </Container>
    );
};