import * as React from "react";
import { List, Datagrid, TextField, EmailField, DateField, Edit, TextInput, Create, SimpleForm } from 'react-admin';

const AdminUserTitle = props => {
    return <span>{props.record ? `${props.record.name}` : ''}</span>;
};

export const AdminUserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export const AdminUserEdit = props => {
    const transform = (record) => {
        delete record.registration_confirmation_token;
        return record;
    }

    return <Edit {...props} title={<AdminUserTitle />} transform={transform}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
        </SimpleForm>
    </Edit>
};

export const AdminUserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
        </SimpleForm>
    </Create>
)