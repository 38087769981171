import React from 'react';
import { Link } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em'
  }
};

const AddNewAdminButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/new-production-admin/${record.id}`}
    label="Add new admin"
    title="Add new admin"
  >
    <PersonIcon />
  </Button>
);

export default withStyles(styles)(AddNewAdminButton);
