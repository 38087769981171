import * as React from "react";
import { List, Datagrid, TextField, DateField, Edit, SimpleForm, TextInput,ReferenceInput, SelectInput, FunctionField } from 'react-admin';

const ProductionTitle = props => {
    return <span>{props.record ? `${props.record.name}` : ''}</span>;
};

export const ProductionList = props => (
    <List {...props} resource="productions">
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="company.name" label="Company" />
            <TextField source="name" />
            <TextField source="address" />
            <DateField source="created_at" />
            <TextField source="admins.length" label="Admins" />
            <FunctionField
                label="Timesheet Template"
                render={record => `${record.timesheet_pdf_template === 'standard' ? 'Standard' : 'Single Per Diem'}`}
            />;

        </Datagrid>
    </List>
);

export const ProductionEdit = props => {
    const transform = (data) => {
        data.company_id = data.company ? data.company.id : undefined;
        delete data.company;
        delete data.admins;
        delete data.timesheet_weeks;
        delete data.departments;
        return data;
    };

    const choices = [
        { id: 'standard', value: 'Standard', },
        { id: 'single_per_diem', value: 'Single Per Diem', },
    ];

    return (<Edit {...props} title={<ProductionTitle />} undoable={false} transform={transform}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput multiline source="address" />
            <ReferenceInput label="Company" source="company.id" reference="companies" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="timesheet_pdf_template" choices={choices} optionText="value" optionValue="id" />
        </SimpleForm>
    </Edit>)
};
