// in src/App.js
import * as React from "react";
import { fetchUtils, Admin, Resource } from 'react-admin';
import basicRestProvider from './basicRestProvider';
import authProvider from './authProvider';
import customRoutes from './customRoutes';
import config from './config';

// Resources
import { UserCreate, UserEdit, UserList } from './resources/users';
import { AdminUserCreate, AdminUserEdit, AdminUserList } from './resources/admin-users';
import { ProductionEdit, ProductionList } from './resources/productions';
import { CompanyCreate, CompanyEdit, CompanyList } from './resources/companies';
import { ProductionShow } from "./resources/productions/show";
import { EmploymentAttributeEdit } from "./resources/employment-attributes";
import { ProductionCreate } from "./resources/productions/create";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth = localStorage.getItem('auth');
    const { bearerToken } = auth ? JSON.parse(auth) : '';
    options.headers.set('Authorization', `Bearer ${bearerToken}`);
    return fetchUtils.fetchJson(url, options);
};
const dataProvider = basicRestProvider(`${config.apiUrl}/admin/v1`, httpClient);
const App = () => (
    <Admin customRoutes={customRoutes} dataProvider={dataProvider} authProvider={authProvider} title="Film Beans Timesheets Admin">
        {permissions => permissions === 'admin' ? [
            <Resource name="admin-users" options={{ label: 'Admins' }} list={AdminUserList} edit={AdminUserEdit} create={AdminUserCreate} />,
            <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />,
            <Resource name="productions" list={ProductionList} edit={ProductionEdit} show={ProductionShow} create={ProductionCreate} />,
            <Resource name="companies" list={CompanyList} edit={CompanyEdit} create={CompanyCreate} />,
            <Resource name="employment-attributes" edit={EmploymentAttributeEdit} />,
        ] : [
            <Resource name="login" />
        ]}
    </Admin>
);

export default App;