import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput, useRedirect, useNotify, useRefresh, Toolbar, SaveButton } from 'react-admin';

/** Toolbar that hides delete (use grid) */
const EmploymenAttributeEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const EmploymentAttributeTitle = props => {
    return <span>{props.record ? `${props.record.label}` : ''}</span>;
};

export const EmploymentAttributeEdit = props => {
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = ({ data }) => {
        notify('ra.notification.updated', 'info', { smart_count: 1 });
        redirect(`/productions/${data.production_id}/show/employment-fields`);
        refresh();
    }

    return (
    <Edit onSuccess={onSuccess} {...props} title={<EmploymentAttributeTitle />} undoable={false}>
        <SimpleForm toolbar={<EmploymenAttributeEditToolbar />}>
            <TextInput source="slug" />
            <TextInput source="label" />
            <BooleanInput source="required" />
        </SimpleForm>
    </Edit>
    );
};