import { stringify } from 'query-string';

const basicRestProvider = (
    apiUrl, httpClient
) => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {

            return {
                data: json,
                total: headers.has('X-Total-Count') ? parseInt(headers.get('X-Total-Count')) : json.length
            };
        });
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        }))
    },

    getMany: (resource, params) => {
        if (params.ids.length === 1) {
            return httpClient(`${apiUrl}/${resource}/${params.ids.pop()}`).then(({ json }) => ({
                data: [json],
            }));
        }

        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        //const rangeStart = (page - 1) * perPage;
        //const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            return {
                data: json,
                total: json.length
            };
        });
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'POST',
            body: JSON.stringify(params.data, (key, value) => {
                if (key === "id" || key === "created_at" || key === "updated_at") {
                    // Our API will never expect / accept these keys in the body
                    return undefined;
                }
                return value;
            }),
        }).then(({ json }) => ({ data: json }))
        .catch((error) => {
            if (error.message.match(/The POST method.*Supported.*PUT/)) {
                // just retry it... h4x central
                return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data, (key, value) => {
                        if (key === "id" || key === "created_at" || key === "updated_at") {
                            // Our API will never expect / accept these keys in the body
                            return undefined;
                        }
                        return value;
                    }),
                }).then(({ json }) => ({ data: json }));
            }
            throw error;
        })

    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }).then(({ json }) => ({ data: json })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
            )
        ).then(responses => ({
            data: responses.map(({ json }) => json.id),
        })),

    getFile: async (resource) => {
        const url = `${apiUrl}/${resource}`;

        const auth = localStorage.getItem('auth');
        const {bearerToken} = auth ? JSON.parse(auth) : '';
        return fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${bearerToken}`,
            },
        })
        .then(async (response) => {
            if (!response.ok) {
                const json = await response.json();
                throw new Error(
                    (json && (typeof json.message !== 'undefined'))?
                    `Server error: ${json.message}` : 
                    json
                );
            }
            return {
                data: await response.blob(),
                fullData: response
            };
        });
    },

    createMultipart: (resource, params) => {
        let formData = new FormData();
        for (var key in params.data) {
            if (typeof params.data[key].rawFile == "object") {
                formData.append(key, params.data[key].rawFile);
            } else {
                formData.append(key, params.data[key]);
            }
        }
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    }
});

export default basicRestProvider;