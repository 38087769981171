import * as React from "react";
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';

export const AddNewAttributeForm = props => {

    const redirect = `/productions/${props.match.params.productionId}/show/employment-fields`;

    const transform = data => {
        if (typeof data.required === 'undefined') {
            data.required = false;
        }
        data.production_id = props.match.params.productionId;
        return data;
    };

    const { staticContext, ...rest } = props;
    return (
    <Create basePath="" resource="employment-attributes" {...rest} transform={transform}>
        <SimpleForm redirect={redirect}>
            <TextInput source="slug" />
            <TextInput source="label" />
            <BooleanInput source="required" />
        </SimpleForm>
    </Create>
    );
};