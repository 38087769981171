import React from 'react';
import { Link } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em'
  }
};

const AddNewAttributeButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/new-employment-attribute/${record.id}`}
    label="Add new attribute"
    title="Add new attribute"
  >
    <PersonIcon />
  </Button>
);

export default withStyles(styles)(AddNewAttributeButton);
