import * as React from "react";
import { List, Datagrid, TextField, DateField, Edit, SimpleForm, TextInput, Create } from 'react-admin';

const CompanyTitle = props => {
    return <span>{props.record ? `${props.record.name}` : ''}</span>;
};

export const CompanyList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="address" />
            <DateField source="created_at" />
            <TextField source="productions.length" label="Productions" />
        </Datagrid>
    </List>
);

export const CompanyEdit = props => (
    <Edit title={<CompanyTitle />} {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="address" multiline />
        </SimpleForm>
    </Edit>
);

export const CompanyCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="address" multiline />
        </SimpleForm>
    </Create>
);