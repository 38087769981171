// in src/customRoutes.js
import * as React from "react";
import { Route } from 'react-router-dom';
import { AddNewAdminGrid } from "./resources/productions/add-new-admin-grid";
import { AddNewAttributeForm } from "./resources/productions/add-new-attribute-form";
import { ValidateUserForm } from "./resources/validate-user-form";

const routes = [
    <Route exact path="/new-production-admin/:productionId" component={AddNewAdminGrid} />,
    <Route exact path="/new-employment-attribute/:productionId" component={AddNewAttributeForm} />,
    <Route exact path="/validate-user/:user/:token" component={ValidateUserForm} />,
];

export default routes;